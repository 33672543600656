/*
 * https://github.com/donavon/use-event-listener
 * Modified to accept array of events
 */
import { useRef, useEffect, useMemo } from 'react';

export const useEventListener = (
  eventName,
  handler,
  options = {},
  element = globalThis
) => {
  const eventNames = useMemo(() => [].concat(eventName), [eventName]);
  const savedHandler = useRef(null);
  const { capture, passive, once } = options;

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const isSupported = element && element.addEventListener;
    if (!isSupported) {
      return;
    }

    const eventListener = event => savedHandler.current(event);
    const opts = { capture, passive, once };
    eventNames.forEach(type => {
      element.addEventListener(type, eventListener, opts);
    });

    return () => {
      eventNames.forEach(type => {
        element.removeEventListener(type, eventListener, opts);
      });
    };
  }, [eventNames, element, capture, passive, once]);
};

export default useEventListener;
